@import "../variables.scss";

.container {
  display: flex;
  width: 100%;
}

.contact {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: black;
  padding-left: 30px;
}

.row {
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0;

  color: $textColor;
  fill: $textColor;
}

.row:hover {
  color: $bandColor;
  fill: $bandColor;
}

.icon {
  height: 100%;
  width: 40px;
  margin-right: 10px;
}

a:visited {
  color: inherit;
}

.text {
  a {
    color: $textColor !important;
  }
}

@media (min-width: $min-width) {
  .container {
    position: fixed;
    bottom: 0;
  }

  .contact {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: black;
    justify-content: space-around;
  }
}
