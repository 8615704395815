@import "../variables.scss";

.container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tours {
  background-color: rgba(0, 44, 42, 0.7);
  border-radius: $border-radius;
  max-width: 300px;
}

.header {
  padding: 10px 0;
  font-size: 28px;
  position: sticky;
  top: 0;
  background-color: #001b1a;
  text-align: center;
}

.tourEntry {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
}

.tourEntryLine {
  line-height: 20px;
}

.tourEntryName {
  color: $gold;
}

.list {
  text-align: center;
  padding: 0 30px;
}

.wrappedText {
  word-wrap: break-word;
}

.eventLink {
  font-weight: bold;
  word-wrap: break-word;
  text-decoration: underline;
  color: $glowing;
}

.eventLink:visited {
  font-weight: bold;
  color: $glowing;
}

.eventLink:hover {
  font-weight: bold;
  text-decoration: none;
  color: $glowing;
}

@media (min-width: $min-width) {
  .header {
    top: 45.5px;
  }
}
