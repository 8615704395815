$border-radius: 20px;

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 20em;
}

.youtube {
  background-color: #000;
  margin-bottom: 5%;
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
  cursor: pointer;
  -webkit-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
  border-radius: $border-radius;
  -webkit-box-shadow: 0 0 15px 5px #000;
  -moz-box-shadow: 0 0 15px 5px #000;
  box-shadow: 0 0 20px 5px #000;
}
.youtube img {
  width: 100%;
  top: -16.84%;
  left: 0;
  opacity: 0.7;
}
.youtube .playButton {
  width: 90px;
  height: 60px;
  background-color: #333;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.6);
  z-index: 1;
  opacity: 0.8;
  border-radius: 6px;
}
.youtube .playButton:before {
  content: "";
  border-style: solid;
  border-width: 15px 0 15px 26px;
  border-color: transparent transparent transparent #fff;
}
.youtube img,
.youtube .playButton {
  cursor: pointer;
}
.youtube img,
.youtube iframe,
.youtube .playButton,
.youtube .playButton:before {
  position: absolute;
}
.youtube .playButton,
.youtube .playButton:before {
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}
.youtube iframe {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
