@import "../variables.scss";

.container {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.bioWrapper {
  max-width: 70%;
  background-color: $transparentBgColor;
  padding: $padding;
  border-radius: $border-radius;
  font-size: 18px;
}

.bandName {
  color: $bandColor;
}

.pressContainer {
  text-align: center;
  margin-top: 50px;
  // padding: 0 5%;
}

.pressHeader {
  font-size: 22px;
  padding: 10px 0;
}

.pressQuote {
  font-style: italic;
  padding: 20px 0;
}

.quoteWithLink {
  color: inherit;
  text-decoration-color: $bandColor;
}

.quoteWithLink:hover {
  color: $bandColor;
}

.source {
  color: $bandColor;
}

@media (min-width: $min-width) {
  .bioWrapper {
    max-width: 40%;
  }
}
