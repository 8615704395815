@import "../../variables.scss";

.menu {
  position: fixed;
  top: 10px;
  left: 10px;
  // background-color: rgba(0, 32, 19, 0.7);
  transition: background-color 0.2s ease-in-out;
  width: 100px;
  padding: 10px;
  border-radius: $border-radius;
  z-index: 2;
  // text-align: center;
  // background-color: rgba(168, 116, 80, 1);
}

.content {
  flex: 1;
}

$navLinkColor: $menuIconColor;

.navLink {
  color: $navLinkColor !important;
  text-decoration: none;
  font-size: 28px;
}

.navLink:visited {
  color: $navLinkColor;
}

.navLinkActive {
  color: $bandColor !important;
}

.menuOpen {
  background-color: rgba(168, 116, 80, 1);
}

.menuItems {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
  padding-left: 5px;
}

.openMenuItems {
  max-height: 130px;
}

.closeMenuItems {
  max-height: 0;
}

.menuIcon {
  fill: $menuIconColor;
  stroke: #15aec4;
  transition: transform 0.2s ease-in-out;
}

.openMenuIcon {
  transform: rotate(90deg);
}

@media (min-width: $min-width) {
  .headerContainer {
    display: flex;
    margin: 50px 0;
    display: flex;
    justify-content: center;
    width: 100%;
    position: sticky;
    top: -1px;
    z-index: 1;
    margin-top: 250px;
  }

  .mobileHeader {
    display: none;
  }

  .menu {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    border-radius: 0;
    transition: background-color 0.2s ease-in-out;
  }

  .navLink {
    color: $navLinkColor;
    text-decoration: none;
    font-size: 40px;
  }

  .menuIcon {
    display: none;
  }

  .menuItems {
    display: flex;
    flex-direction: row;
    max-height: initial;
    justify-content: space-evenly;
    width: 100%;
  }

  .menuOpen {
    background-color: initial;
  }

  .headerSticked {
    background-color: black;
  }
}
