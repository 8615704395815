body,
html,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  background-color: black;
}

@font-face {
  font-family: "mic-32";
  src: url("./assets/fonts/mic-32regular-webfont.woff") format("woff"),
    url("./assets/fonts/mic-32regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/mic-32regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
