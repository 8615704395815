@import "../variables.scss";

.pageContainer {
  // height: 100%;
  width: 100%;
  background-image: url("../../assets/images/background-with-logo.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  font-family: "mic-32";
  color: $textColor;
}

.scrollableContent {
  overflow-y: auto;
  height: 100vh; // Very important, otherwise, for some reason, it's the entire document which is scrolling
  // display: flex;
  // flex-direction: column;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
}

@media (min-width: $min-width) {
  .scrollableContent {
    padding-bottom: 100px;
  }
}