$transparentBgColor: rgba(0, 39, 31, 0.9); // rgba(0, 32, 19, 0.8); //rgba(0, 63, 36, 0.712);
$bandColor: rgb(235, 185, 161);
$padding: 30px 20px;
$border-radius: 20px;

$textColor: white;

$menuIconColor: #beedec;

$min-width: 720px;

$glowing: #15aec4;

$gold: #dfb200;
