@import "../pages/variables.scss";

.container {
  height: 100%;
  width: 100%;
  margin: 0;

  background-image: url("../assets/images/background-with-logo.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  font-family: "mic-32";
  color: $textColor;
  // overflow-y: auto;
}
