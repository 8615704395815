@import "../variables.scss";

.container {
  width: 100%;
  color: $textColor;

  margin-top: 50px;
}

.videoContainer {
  padding: 0;
}

@media (min-width: $min-width) {
  .videoContainer {
    padding: 0 25%;
  }
}
