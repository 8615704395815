@import "../../variables.scss";

$dimension: 30px;

.container {
  display: flex;
  flex-direction: row;
  height: $dimension;
  width: 100%;
  justify-content: space-evenly;
  padding-top: 220px;
  padding-bottom: 50px;
}

.icon {
  fill: $menuIconColor;
  stroke: $glowing;
  stroke-width: 0.25px;
  width: $dimension;
  height: $dimension;
  // border: 1px solid red;
}
